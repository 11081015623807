import { useEffect, useState } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Center, CircularProgress, CircularProgressLabel, Text, VStack } from '@chakra-ui/react';

import { PageWrapper, InactivityTimerNavigate, InteractionCheck } from '@components';

import { useTranslation } from 'react-i18next';

const videoLengthInSeconds = 5;

const updateCircularProgress = ({ intervalRef, progressValue, setProgressValue }) => {
  let currentProgress = progressValue;
  return () => {
    if (currentProgress >= 100) {
      clearInterval(intervalRef);
    } else {
      currentProgress = currentProgress + 1;
      setProgressValue(currentProgress);
    }
  }
}

const sendPusherEvent = ({ locationId, locationAction, interactionId }) => {

  // Send request to change pattern on the device
  const messageBody = { locationId, locationScreen: "HoldToPlay", locationAction, interactionId };
  const apiSubDomain = `api-${window.location.hostname.split('.').shift()}`;

  fetch(`https://${apiSubDomain}.adidasdigital.com`, {
    method: `POST`,
    body: JSON.stringify(messageBody)
  })
    .then(response => response.json())
    .then(data => console.log(data));

}

const HoldToPlay = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [buttonsVisibility, setButtonsVisibility] = useOutletContext();
  const [intervalRef, setIntervalRef] = useState();
  const [isIntervalInitialised, setIsIntervalInitialised] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [interactionId, setInteractionId] = useState('');

  const { locationId } = useParams();

  useEffect(() => {
    if (buttonsVisibility.isLanguageButtonVisible !== true || buttonsVisibility.isBackButtonVisible !== true) {
      setButtonsVisibility({ isLanguageButtonVisible: true, isBackButtonVisible: true });
    }

    // Get interactionId from session.
    const interactionIdFromStorage = sessionStorage.getItem(`interactionId`);
    setInteractionId(interactionIdFromStorage);

    sendPusherEvent({ locationId, locationAction: "", interactionId: interactionIdFromStorage });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIntervalInitialised === true && progressValue < 100 && typeof intervalRef === `undefined`) {
      const intervalReturnValue = setInterval(updateCircularProgress({ intervalRef, progressValue, setProgressValue }), videoLengthInSeconds * 10);
      setIntervalRef(intervalReturnValue);
      sendPusherEvent({ locationId, locationAction: "start", interactionId })
    } else if (isIntervalInitialised === false) {
      clearInterval(intervalRef);
      setIntervalRef();
      sendPusherEvent({ locationId, locationAction: "stop", interactionId })
    } else if (progressValue >= 100) {
      clearInterval(intervalRef);
      setIntervalRef();
      setTimeout(() => navigate(`/home/explore-the-future/${locationId}`), 700);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntervalInitialised, progressValue]);

  return (
    <PageWrapper>
      <VStack>
        <Text as="ul" mb={3} textAlign="left" w="full" fontWeight={100} lineHeight="14px">
          [{t('holdToPlay.intro')}]:
          <br />+
          <br />+
          <br />+///
        </Text>
        <Box>
          <CircularProgress
            color={`white`}
            size={48}
            thickness={3}
            trackColor="none"
            capIsRound
            value={progressValue}
          >
            <CircularProgressLabel>
              <Center>
                <Box userSelect="none" w="36" h="36" borderStyle="solid" borderWidth={1} borderColor={0} borderRadius="full" >
                  <Button
                    mt={2}
                    variant="hold"
                    fontSize="5xl"
                    onMouseDown={() => setIsIntervalInitialised(true)}
                    onMouseUp={() => setIsIntervalInitialised(false)}
                    onTouchStart={(event) => { event.preventDefault(); setIsIntervalInitialised(true) }}
                    onTouchEnd={(event) => { event.preventDefault(); setIsIntervalInitialised(false) }}
                    userSelect="none"
                  >
                    Hold
                  </Button>
                </Box>
              </Center>
            </CircularProgressLabel>
          </CircularProgress>
        </Box>
      </VStack>
      {isIntervalInitialised === false && <InactivityTimerNavigate timeout={10} navigateUrl={`/home/still-there/${locationId}`} />}
      <InteractionCheck pageName={`HoldToPlay`} />
    </PageWrapper>
  )
}

export default HoldToPlay
