import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShortUniqueId from 'short-unique-id';

// Check that the User is the one controling the location or 
// can take control of the location.
const InteractionCheck = ({ pageName }) => {
  const navigate = useNavigate();
  const [interactionId, setInteractionId] = useState('');

  const { locationId } = useParams();

  useEffect(() => {
    // Get interactionId from session.
    const interactionIdFromStorage = sessionStorage.getItem(`interactionId`);
    if (interactionIdFromStorage === null) {
      const uid = new ShortUniqueId({ length: 12, dictionary: `alphanum_lower` });
      const newInteractionId = uid();
      sessionStorage.setItem(`interactionId`, newInteractionId);
      setInteractionId(newInteractionId);
    }
    else {
      setInteractionId(interactionIdFromStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (interactionId !== ``) {
      // Check if the User is the current User.
      const apiSubDomain = `api-${window.location.hostname.split('.').shift()}`;

      new Promise((resolve) => {
        fetch(`https://${apiSubDomain}.adidasdigital.com`, {
          method: `POST`,
          body: JSON.stringify({ locationId, interactionId, pageName })
        })
          .then(response => response.json())
          .then(data => {
            if (data.success === false) {
              resolve(navigate(`/home/currently-in-use/${locationId}`));
            }
            resolve();
          })
          .catch(() => {
            resolve();
          })
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionId]);

  return null
}

export default InteractionCheck
