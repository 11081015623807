import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Pusher from 'pusher-js/react-native';

import { Box } from '@chakra-ui/react';

import { ScreenIdle, ScreenHoldToplay, ScreenMessage } from '@components';

import i18n from '../i18n';

// This sets up the screen and starts playing the idle screen video.
const defaultPusherPayload = { screen: "Idle", action: "start" };

// Pusher.logToConsole = true;

const DisplayPage = () => {
  const [pusherPayload, setPusherPayload] = useState(defaultPusherPayload);
  const [actionScreenHoldToplay, setActionScreenHoldToplay] = useState(null);
  const [showScreenIdle, setShowScreenIdle] = useState(true);
  const [showScreenHoldToplay, setShowScreenHoldToplay] = useState(false);
  const [showScreenMessage, setShowScreenMessage] = useState(false);
  const [timeoutId, setTimeoutId] = useState(0);

  const { locationId, locationLanguage } = useParams();

  useEffect(() => {
    const { locationScreen, locationAction } = pusherPayload;
    switch (locationScreen) {
      case "HoldToPlay":
        if (locationAction !== `start`) {
          if (timeoutId !== 0) {
            clearTimeout(timeoutId);
            setTimeoutId(0);
          }
          setShowScreenIdle(false);
          setShowScreenHoldToplay(true);
          setShowScreenMessage(false);
        }
        setActionScreenHoldToplay(locationAction);
        break;
      case "Message":
        setShowScreenIdle(false);
        setShowScreenHoldToplay(false);
        setShowScreenMessage(true);
        setTimeoutId(setTimeout(() => {
          setTimeoutId(0);
          setPusherPayload({ locationScreen: "Idle", locationAction: "" });
        }, 15000))
        break;
      case "Idle":
      default:
        if (timeoutId !== 0) {
          clearTimeout(timeoutId);
          setTimeoutId(0);
        }
        setShowScreenIdle(true);
        setShowScreenHoldToplay(false);
        setShowScreenMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pusherPayload]);

  useEffect(() => {
    let pusherID = ``;
    switch (window.location.hostname.split('.').shift()) {
      case `dev-4dfwd`:
      case `localhost`:
        pusherID = process.env.REACT_APP_DEV_CLIENT_PUSHER_ID;
        break;
      default:
        // Defaults to production
        pusherID = process.env.REACT_APP_CLIENT_PUSHER_ID;
        break;
    }
    const pusher = new Pusher(pusherID, {
      cluster: 'eu'
    });

    const channel = pusher.subscribe(`location_${locationId}`);
    channel.bind('action', (data) => {
      const parsedData = JSON.parse(data.message);
      setPusherPayload(parsedData);
    });

    // Set the language based off the querystring parameter.
    i18n.changeLanguage(locationLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box textAlign="center">
      {showScreenIdle === true && <ScreenIdle />}
      {showScreenHoldToplay === true && <ScreenHoldToplay setAction={actionScreenHoldToplay} />}
      {showScreenMessage === true && <ScreenMessage />}
    </Box>
  )
}

export default DisplayPage;
